<div>
	<ng-container [ngSwitch]="displayPage">
		<ng-container *ngSwitchCase="'default'">
			<ng-container *ngTemplateOutlet="t_showList"></ng-container>
		</ng-container>
		<ng-container *ngSwitchCase="'view'">
			<ng-container *ngTemplateOutlet="t_showPlan"></ng-container>
		</ng-container>
		<ng-container *ngSwitchCase="'cancel'">
			<ng-container *ngTemplateOutlet="t_showCancel"></ng-container>
		</ng-container>
		<ng-container *ngSwitchCase="'payment'">
			<ng-container *ngTemplateOutlet="t_showPayment"></ng-container>
		</ng-container>
		<ng-container *ngSwitchCase="'change-plan'">
			<ng-container *ngTemplateOutlet="t_changePayment"></ng-container>
		</ng-container>

	</ng-container>
</div>

<ng-template #t_showList>

	<ng-container *ngIf="orgList?.length">
		<!-- TODO: @remove remove-subscription-required : refactor title -->
		<div class="LabelBlack500 uppercase" style="margin: 32px 0 16px 0;"
			*featureEnabled="'!remove-subscription-required'">{{orgList.length}} Plans & Billing Teams</div>
		<div class="LabelBlack500 uppercase" style="margin: 32px 0 16px 0;"
			*featureEnabled="'remove-subscription-required'">{{orgList.length}} Organizations</div>

		<mat-divider color="#00000026"></mat-divider>

		<mat-list class="payment-org-list">
			<ng-container *ngFor="let team of orgList">
				<mat-list-item data-testid="team-name" (click)="selectTeam(team);">
					<span class="list-name"> {{team?.name}} </span>
					<mat-icon style="margin: auto 0 auto auto; font-size: 32px;">chevron_right</mat-icon>
				</mat-list-item>

				<!-- TODO: @remove remove-subscription-required : remove subscription help text -->
				<ng-container *featureEnabled="'!remove-subscription-required'">
					<span class="account-issue" *ngIf="!team.chargebee_id">Your team has an issue with its subscription,
						please
						<button mat-button class="link" (click)="sendFeedback()">
							contact support
						</button>
					</span>
				</ng-container>
			</ng-container>
		</mat-list>

		<info-box *ngIf="orgList.length > 1">
			<!-- TODO: @remove remove-subscription-required : replace the info message -->
			<span *featureEnabled="'!remove-subscription-required'">
				<div class="H7Black700Regular" style="margin-bottom: 8px;">Why am I seeing multiple plan & billing
					teams?</div>
				<div class="H7Black700Semibold">
					If you are added to a team as an ‘Admin’, you have access the team’s subscription plan, and billing
					configuration. You can edit your access to ‘View, edit, & process’ if you do not want to access to a
					team’s plan & billing.
				</div>
			</span>

			<span *featureEnabled="'remove-subscription-required'">
				<div class="H7Black700Regular" style="margin-bottom: 8px;">Why am I seeing multiple organizations?</div>
				<div class="H7Black700Semibold">
					If you are added to an organization as an ‘Admin’, you have access the organization's billing
					methods.
					You can edit your access to ‘View, edit, & process’ if you do not want to access to an
					organization’s billing information.
				</div>
			</span>
		</info-box>
	</ng-container>
</ng-template>


<ng-template #t_showPlan>
	<div class="back-container" *ngIf="selectedTeam">
		<div class="back-inner">
			<button mat-icon-button (click)="goBack()" style="margin-right: 8px;">
				<mat-icon style="font-size: 24px;">keyboard_backspace</mat-icon>
			</button>
			<span class="H4Black700Regular">
				{{selectedTeam.name}}
			</span>
		</div>
		<!-- TODO: @remove temp-new-subscription -->
		<ng-container *featureEnabled="'temp-new-subscription'">
			<button class="secondary-button"
					mat-stroked-button
					*ngIf="showBillingButton(selectedTeam)"
					[disabled]="showChargebeeError || !userIsAdmin"
					(click)="openChargebeePortal(selectedTeam)">
				Manage Billing
			</button>
		</ng-container>
	</div>
	<span *ngIf="selectedTeam.license_info" class="license-info">
		Available Licenses: Admin: <b>{{teamRole.admin}} of {{selectedTeam.license_info.max_seats.admin}}</b>, Creator: <b>{{teamRole.process}} of {{selectedTeam.license_info.max_seats.creator}}</b>, Viewer: <b>{{teamRole.reader}} of {{selectedTeam.license_info.max_seats.viewer}}</b>
	</span>
	<span *ngIf="selectedTeam.max_storage" class="license-info">
		Storage: {{selectedTeam.current_storage | bytes}} of {{selectedTeam.max_storage | bytes}} ({{selectedTeam.current_storage/selectedTeam.max_storage * 100}}%) used
	</span>
	<!-- TODO: @remove remove-subscription-required  remove plan info
		though maybe we should just comment it out since we might need it again in the future? -->
	<div *featureEnabled="'!remove-subscription-required'" class="confirmation-group" style="margin-top: 32px">
		<div class="group-container">
			<div class="header">Current plan:</div>
			<span class="body" *ngIf="isSubscribed">
				<div class="H7Black700Semibold">
					Mapware: {{ chargeBeePlansForDisplay?.name }}
				</div>
				<div class="H7Black500Regular">
					{{ chargeBeePlansForDisplay.description.header }}
				</div>
				<div class="H7Black700Regular chargebee-plan-detail"
					*ngFor="let detail of chargeBeePlansForDisplay.description.details">
					<mat-icon style="font-size: 22px; color: #02adc0; margin-right: 8px">
						done
					</mat-icon>
					{{ detail}}
				</div>
				<div style="margin-top: 16px; display: flex" class="H7BlackRegular">
					{{ chargeBeePlansForDisplay.expirationMessage }}
				</div>

				<div class='plan-select' *ngIf="isUserAdmin() && selectedTeam.subscription?.renew">
					<span style="margin-right: 8px">Change Plan:</span>
					<mat-select primary data-testid="select-plan" [(value)]="subscriptionPlanId"
						(selectionChange)="onChangeSubscriptionSelect($event.value)">
						<mat-option *ngFor="let option of displayPlans" [value]="option.id">{{option.name}}</mat-option>
					</mat-select>
				</div>
			</span>

			<span *ngIf="!isSubscribed">
				<div class="H7Black700Semibold">
					No active subscription.
				</div>
				<app-contact-component>
					to upgrade your subscription
				</app-contact-component>
			</span>
		</div>
	</div>

	<!-- TODO: @remove remove-subscription-required : remove billing cycle info
		though maybe we should just comment it out since we might need it again in the future? -->
	<div *featureEnabled="'!remove-subscription-required'" class="confirmation-group">
		<div class="group-container">
			<span class="header">Billing cycle:</span>
			<span class="body" *ngIf="isSubscribed">
				<div class="H7Black700Semibold">
					{{ selectedTeam.subscription?.billing_period_unit == "year" ? "Yearly" : "Monthly" }}
				</div>
				<div class="H7Black700Regular" style="margin-top: 8px">
					{{ selectedTeam.subscription?.price | currency}}
					per {{
					selectedTeam.subscription?.billing_period_unit == "year"
					? "year"
					: "month"
					}} (excl. tax)
				</div>
				<div class="H7Black500Regular" *ngIf="selectedTeam.subscription?.renew">
					Next billing: {{ selectedTeam.subscription?.expires_at | date }}
				</div>
				<div class="H7Black500Regular" *ngIf="!selectedTeam.subscription?.renew">
					Subscription ends: {{ selectedTeam.subscription?.expires_at | date }}
				</div>
				<div class="body-tool" *ngIf="selectedTeam.subscription?.renew">
					<span class="H7RedRegular cancel-subscription" (click)="togglePage('cancel', true)"
						*ngIf='isUserAdmin() && selectedTeam.subscription?.renew'>
						Cancel subscription
					</span>
				</div>
				<div class="body-tool" *ngIf="!selectedTeam.subscription?.renew">
					<span class="link" style="display: flex;" (click)="renewSubscription(selectedTeam.subscription)">
						Renew subscription
					</span>
				</div>
				<div class="H7RedSemibold" style="margin-top: 16px" *ngIf="showUpdateError">
					<div style="margin-top: 16px">
						Your subscription could not be updated, please try again.
					</div>
					<div>
						If this issue persists please
						<button mat-button class="link" (click)="sendFeedback()">
							contact support
						</button>
						.
					</div>
				</div>
			</span>
			<span *ngIf="!isSubscribed">- -</span>
		</div>
	</div>

	<div class="confirmation-group">
		<!-- TODO: @remove temp-new-subscription -->
		<ng-container *featureEnabled="'temp-new-subscription'">
			<div class="plan-group">
				<div class="body" *ngIf="planOptions">
					<ng-container *ngTemplateOutlet="t_subscriptionSelection"></ng-container>
				</div>
				<div class="dot-container" *ngIf="isProcessing">
					<div class="dot-stretching"></div>
				</div>
			</div>
		</ng-container>
		<ng-container *featureEnabled="'!temp-new-subscription'">
			<div class="group-container">
				<span class="header">Payment cards:</span>
				<span class="body">
					<div *ngFor="let card of cardList; let i = index;">
						<div class="card-row-item" [ngClass]="{'primary-card' : cardList[i].isPrimary}">
							<div class="H7Black700Regular">
								<ng-container *ngIf="cardList[i].isPrimary">
									<div class="H7Black700Semibold">Primary card</div>
									<div class="card-info">
										<div class="card-brand">{{cardList[i].brand | titlecase }}</div>
										<div>XXXX XXXX XXXX {{cardList[i].last4}}</div>
									</div>
								</ng-container>
								<div class="card-options" *ngIf="!cardList[i].isPrimary">
									<button mat-button style="width: auto; padding: 0 8px; margin-left: -8px;"
										[matMenuTriggerFor]="cardMenu">
										<div class="card-info">
											<div class="card-brand">{{cardList[i].brand | titlecase }}</div>
											<div>XXXX XXXX XXXX {{cardList[i].last4}}</div>
										</div>
										<mat-icon>keyboard_arrow_down</mat-icon>
									</button>

									<mat-menu #cardMenu="matMenu" position="after" class="card-menu">
										<mat-list class="H7Black700Semibold">
											<mat-list-item style="height: 40px;" [matMenuTriggerFor]="cardMenu"
												(click)="makePrimary(card)">
												<mat-icon style="margin-right: 16px;">star_outline</mat-icon>
												<span>Make primary</span>
											</mat-list-item>
											<mat-list-item *ngIf="cardList.length > 1" style="height: 40px;"
												[matMenuTriggerFor]="cardMenu" (click)="openConfirmationModal(card)">
												<mat-icon style="margin-right: 16px;">delete_outline</mat-icon>
												<span data-testid="delete-card">Delete</span>
											</mat-list-item>
										</mat-list>
									</mat-menu>
								</div>
							</div>
						</div>
					</div>

					<span *ngIf="!hasPaymentCards">
						Your account has no saved payment cards.
						You will only need a payment card to purchase more credits when you run out.
					</span>

					<div class="tool" (click)="togglePage('payment')">
						<mat-icon>add</mat-icon>
						<span>Add a payment card</span>
					</div>
				</span>
			</div>
			<info-box *featureEnabled="'remove-subscription-required'">
				You will only need your credit card to purchase credits for processing models. These charges will appear
				in your account as "Mapware (mapware.ai)".
			</info-box>
		</ng-container>
		<!-- <div class="confirmation-group">
			<div class="container">
				<span class="header">Subscription billing history:</span>
				<span>{{user.billing ? user.billing : '- -'}}</span>
				<span class="body">
					<div class="H7Black700Semibold">Show billing history during the last: </div>
					<mat-select primary style="width: 100%; margin-top: 8px;" [(value)]="billingHistory">
						<mat-option *ngFor="let option of billingHistoryOptions" [value]="option">{{option.text}}</mat-option>
					</mat-select>
				</span>
			</div>
		</div> -->
	</div>
</ng-template>

<!-- TODO: @remove remove-subscription-required : t_showCancel template
			though maybe we should just comment it out since we might need it again in the future? -->
<ng-template #t_showCancel>
	<span class="AApp_Logo"></span>

	<mat-horizontal-stepper linear class="stepper-no-header cancel-stepper">
		<mat-step style="text-align: center;">
			<div style="margin: 16px 8px 0 8px; width: 476px;">
				<div class="H3Black700" style="margin-bottom: 16px;">Are you sure you want to cancel your subscription?
				</div>
				<div class="H6Black700Regular">Cancelling Mapware’s Professional subscription will prevent all your team
					members from uploading any photos and from uploading and downloading any projects, photos, models,
					data, or related files at the end of the current billing cycle.</div>
				<div class="H6Black700Regular" style="margin-top: 16px;">Click ‘Next’ to continue cancelling your
					subscription.</div>
				<div style="display: flex; margin-top: 48px;">
					<button mat-stroked-button color="primary" (click)="togglePage('view', true);">Back</button>
					<button mat-stroked-button color="primary" style="width: 135px;" matStepperNext>Next</button>
				</div>
			</div>
		</mat-step>
		<mat-step style="text-align: center;">
			<div style="margin: 16px 8px 0 8px; width: 476px;">
				<div class="H3Black700" style="margin-bottom: 32px;">Please check all boxes to confirm your cancellation
				</div>
				<div class="H6Black700Semibold" style="margin-bottom: 24px;">By cancelling my subscription, I understand
					that: </div>
				<div class="H6Black700Regular" style="margin-bottom: 24px; display: flex;">
					<mat-checkbox data-testid="cancel-confirmation-checkbox"
						[(ngModel)]="cancelCheckboxes[0]"></mat-checkbox>
					<span style="margin-left: 8px;"> I can access my projects and data until the end of my current
						billing cycle. ({{selectedTeam.subscription?.expires_at | date}})</span>
				</div>
				<div class="H6Black700Regular" style="margin-bottom: 24px; display: flex;">
					<mat-checkbox data-testid="cancel-confirmation-checkbox"
						[(ngModel)]="cancelCheckboxes[1]"></mat-checkbox>
					<span style="margin-left: 8px;"> All projects and their related data will be deleted.</span>
				</div>
				<div class="H6Black700Regular" style="margin-bottom: 24px; display: flex;">
					<mat-checkbox data-testid="cancel-confirmation-checkbox"
						[(ngModel)]="cancelCheckboxes[2]"></mat-checkbox>
					<span style="margin-left: 8px;"> My team members will no longer have access to these
						projects.</span>
				</div>
				<div class="H6Black700Regular" style="margin-bottom: 24px; display: flex;">
					<mat-checkbox data-testid="cancel-confirmation-checkbox"
						[(ngModel)]="cancelCheckboxes[3]"></mat-checkbox>
					<span style="margin-left: 8px;"> If I want to recover the deleted projects in the future, I will
						have to pay a back-pay storage fee. <a href="http://manual.mapware.io" target="_blank">Learn
							more</a></span>
				</div>
				<button data-testid="confirm-cancel-button" mat-stroked-button color="primary" style="width: 100%;"
					[disabled]="!checkAllChecked() || isProcessing" (click)="cancelSubscription()">Cancel
					subscription</button>
				<button mat-stroked-button color="primary" [disabled]="isProcessing"
					(click)="togglePage('view', true);">Cancel</button>
				<mat-error *ngIf="cancelError" class="H6Black700Regular"
					style="margin: 16px 0;font-size:14px;">{{cancelError}} If this issue persists, please <button
						mat-button class="link" (click)="sendFeedback();">contact support</button>.</mat-error>

				<div class="dot-container">
					<div class="dot-stretching" *ngIf="isProcessing"></div>
				</div>
			</div>
		</mat-step>
		<mat-step style="text-align: center;">
			<div class="cancel-container">
				<div class="H2Black700" style="margin-bottom: 16px;">Your subscription has been cancelled</div>
				<div class="CenterH6Black700Regular">Your projects and data will be deleted after
					{{selectedTeam.subscription?.expires_at | date}}.</div>
				<button mat-stroked-button color="primary" class="continue-button" (click)="togglePage('view', true);"
					matDialogClose>Continue to Mapware</button>
			</div>
		</mat-step>
	</mat-horizontal-stepper>

</ng-template>

<ng-template #t_showPayment>
	<div class="back-container">
		<div class="H4Black700Regular" style="display: flex;">
			<button mat-button (click)="togglePage('view');">
				<mat-icon>keyboard_backspace</mat-icon>
			</button>
			<span>Add new card</span>
		</div>
	</div>

	<app-chargebee-card-input [showSubHeader]="true" nextButtonText="Add Card" backButtonText="Cancel"
		(onCardSuccess)="addCard($event);" (back)="togglePage('view');">
	</app-chargebee-card-input>

</ng-template>

<!-- TODO: @remove remove-subscription-required : t_changePayment template
			though maybe we should just comment it out since we might need it again in the future? -->
<ng-template #t_changePayment>
	<div style="margin: 16px 8px 0 8px; width: 476px;">
		<div class="H3Black700" style="margin-bottom: 16px;">Are you sure you want to change your subscription?</div>
		<div class="confirmation-group">
			<div class="group-container">
				<div class="header">New plan:</div>
				<span class="body">
					<div class="H7Black700Semibold">
						Mapware: {{ newPlanForDisplay?.name }}
					</div>
					<div class="H7Black500Regular">
						{{ newPlanForDisplay.description.header }}
					</div>
					<div class="H7Black700Regular chargebee-plan-detail"
						*ngFor="let detail of newPlanForDisplay.description.details">
						<mat-icon style="font-size: 22px; color: #02adc0; margin-right: 8px">
							done
						</mat-icon>
						{{ detail}}
					</div>
					<div class="H7Black700Semibold">
						{{ newPlanForDisplay.price | currency }}
						per {{
						newPlanForDisplay?.isYearly
						? "year"
						: "month"
						}} (excl. tax)
					</div>
				</span>
			</div>
		</div>
		<div class="H6Black700Regular" style="margin-top: 16px; display: flex; justify-content: flex-end;">
			Click ‘Yes’ to change your subscription.
		</div>
		<div style="display: flex; margin-top: 24px;">
			<button mat-stroked-button color="primary" [disabled]="isProcessing"
				(click)="cancelChangeSubscription();">Back</button>
			<button mat-stroked-button color="primary" style="width: 135px;" [disabled]="isProcessing"
				(click)='changeSubscription(newPlanForDisplay)'>Yes</button>
		</div>
	</div>
	<mat-error *ngIf="cancelError" style="margin: 16px 0;font-size:14px;">{{cancelError}} If this issue persists, please
		<button mat-button class="link" (click)="sendFeedback();">contact support</button>.</mat-error>
	<div class="dot-container" style="margin-top: 16px">
		<div class="dot-stretching" *ngIf="isProcessing"></div>
	</div>
</ng-template>

<ng-template #t_subscriptionSelection>
	<div *ngIf="showPlanChangeNotification" class="H7RedSemibold">Any changes to your subscription may not be
		immediately reflected here, please wait or check back in a few minutes.</div>
	<div *ngIf="showChargebeeError" class="H7RedSemibold">Subscriptions cannot be managed at this time. If this
		continues, please <span class="link" (click)="sendFeedback();">contact support</span>.
	</div>
	<div class="plan-header">
		<ng-container *ngIf="userIsAdmin">
			<div class="H3Black700">{{hasOrgPlan(selectedTeam) ? "Your Plan" : "Select a Plan"}}</div>
			<div *ngIf="hasOrgPlan(selectedTeam)" class="H7Black500Regular">To change your plan, select "Manage
				Billing", your current plan, and then "Edit Subscription".</div>
		</ng-container>
		<div *ngIf="!userIsAdmin" class="H7Black500Regular">You do not have permission to manage subscriptions or
			billing. Please contact your organization’s Admin for assistance.</div>
		<div class="period-container" *ngIf="!hasOrgPlan(selectedTeam)" [ngClass]="{'disabled': !userIsAdmin}">
			<ng-container *ngFor="let period of periodOptions">
				<div class="period" *ngIf="period.list.length" [ngClass]="{'active-period': isSelectedPeriod(period)}"
					(click)="selectedPeriod = period;">
					{{period.name}}
				</div>
			</ng-container>
		</div>
	</div>
	<div class="plans-container elevation-z2" *ngIf="selectedPeriod" [ngClass]="{'disabled': !userIsAdmin}">
		<mat-radio-group [(ngModel)]="selectedPlan">
			<ng-container *ngFor="let plan of planOptions[selectedPeriod.value]">
				<div *ngIf="hasNoPlanOrCurrentPlan(plan, selectedTeam)" class="plan"
					[ngClass]="{'active-plan elevation-z5': isSelectedPlan(plan)}">
					<div class="plan-header" (click)="setSelectedPlan(plan)">
						<mat-radio-button [value]="plan" *ngIf="showPlanSelect(plan, selectedTeam)"></mat-radio-button>

						<div class="plan-block">
							<div class="plan-title">
								<div class="H5Black700 bold ellipsis">{{ plan.name }}</div>
								<!--								TODO: Reimplement or remove after discussion on future of plan purchasing -->
								<div *ngIf="isOrgPlan(plan, selectedTeam)" class="current-plan"
									[ngClass]="getPlanStatusClass(selectedTeam)">
									{{ getPlanStatusText(selectedTeam) }}
								</div>
							</div>
							<div class="P2Black500">{{plan.meta_data ? plan.meta_data.plan_subtext : ''}}</div>
						</div>

						<div class="price-block" *ngIf="plan.price">
							<div class="H5Black500">{{ ( plan.price / 100 ) | currency }}</div>
							<div class="P2Black500">{{ plan.meta_data?.plan_savings ? plan.meta_data.plan_savings :
								selectedPeriod.name }}</div>
						</div>
						<div class="price-block" *ngIf="!plan.price && plan.meta_data?.plan_price_1">
							<div class="H5Black500">{{ plan.meta_data.plan_price_1 }}</div>
							<div class="P2Black500">{{ plan.meta_data.plan_price_2 }}</div>
						</div>
					</div>

					<div *ngIf="isSelectedPlan(plan) && plan.meta_data?.plan_features?.length" class="plan-info">
						<div *ngFor="let feature of plan.meta_data.plan_features" class="feature-block">
							<mat-icon class="">check</mat-icon>
							{{ feature }}
						</div>
						<div class="P2Black500">{{ plan.meta_data.plan_overages }}</div>
					</div>

					<div *ngIf="isSelectedPlan(plan)" class="plan-footer">
						<button mat-flat-button color="primary" (click)="selectPlan(plan);"
							*ngIf="showPlanSelect(plan, selectedTeam)">
							<span *ngIf="!isProcessing">{{getPlanSelectText( plan, selectedTeam )}}</span>
							<div *ngIf="isProcessing" class="dot-container">
								<div class="dot-stretching" *ngIf="isProcessing"></div>
							</div>
						</button>
					</div>
				</div>
			</ng-container>
		</mat-radio-group>
	</div>
</ng-template>
