<ng-container *ngIf="determinePromiseStatesFinished(promiseStates)" [ngSwitch]="createDisplay">
	<ng-container *ngSwitchCase="false">
		<ng-container *ngTemplateOutlet="t_listTeams"></ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="true">
		<ng-container *ngTemplateOutlet="t_createTeam"></ng-container>
	</ng-container>
</ng-container>

<ng-template #t_listTeams>

	<button mat-stroked-button color="primary" style="margin: 24px 0;"
		(click)="toggleCreateTeam();"><mat-icon>add</mat-icon><span style="margin: auto 0;"> Create new team
		</span></button>

	<mat-expansion-panel style="margin-bottom: 24px;" *ngFor="let org of orgList; let i = index;">
		<mat-expansion-panel-header collapsedHeight="13rem" expandedHeight="13rem"
			(contextmenu)="clickedRight($event, i)" [ngClass]="{'inner-panel-expanded': org.allProjectsVisible}">
			<mat-panel-title>
				<span class="panel-content">
					<div class="panel-title">
						<mat-icon *ngIf="org.id == user.default_organization_id" matTooltip="Your Default Team"
							type="material">star_border</mat-icon>
						{{org.name}}
					</div>
					<div *ngIf="org.projects" class="phone-hidden project-list-container">
						<span>
							Organization ID: {{org.id}} Projects: {{org.projects.length}} Users: {{org.users.length}}
						</span>
					</div>
					<div *ngIf="org.license_info" class="phone-hidden project-list-container">
						<span>
							Available Licenses: Admin: <b>{{org.license_info.current_seats?.admin}} of {{org.license_info.max_seats?.admin}}</b>, Creator: <b>{{org.license_info.current_seats?.process}} of {{org.license_info.max_seats?.creator}}</b>, Viewer: <b>{{org.license_info.current_seats?.reader}} of {{org.license_info.max_seats?.viewer}}</b>
						</span>
					</div>
					<div *ngIf="org.max_storage" class="phone-hidden project-list-container">
						<span>
							Storage: {{org.current_storage | bytes}} of {{org.max_storage | bytes}} ({{org.current_storage/org.max_storage * 100}}%) used
						</span>
					</div>
				</span>
				<button data-testid="teams-menu" mat-icon-button [matMenuTriggerFor]="optionsMenu" class="more-options"
					(click)="$event.stopPropagation();"><mat-icon>more_horiz</mat-icon></button>
				<mat-menu #optionsMenu="matMenu" class="little-menu centered-menu-text">
					<ng-template matMenuContent>
						<mat-list>
							<mat-list-item *ngIf="userHasOrgPermission(org, 'reader')" [disabled]="user.default_organization_id === org.id"
								(click)="setUserDefaultOrg(org);">
								<mat-icon type="material">star_border</mat-icon>
								<span>Set as Default Team</span>
							</mat-list-item>
							<mat-list-item *ngIf="userHasOrgPermission(org, 'admin')" (click)="openRenameModal(org);">
								<mat-icon> edit </mat-icon>
								<span> Rename Team </span>
							</mat-list-item>
							<mat-list-item *ngIf="orgList?.length > 1 && userHasOrgPermission(org, 'admin')"
								(click)="deleteTeam(org);">
								<mat-icon>delete_outline</mat-icon>
								<span> Delete Team </span>
							</mat-list-item>
						</mat-list>
					</ng-template>
				</mat-menu>
			</mat-panel-title>
			<!-- <mat-panel-description>{{org.desc}}</mat-panel-description> -->
		</mat-expansion-panel-header>

		<div *ngIf="org.addDisplay">
			<app-invite [selectedOrg]="org" [orgPermissions]="signedInUserPermission[org?.id] ?? {}"
				(cancel)="org.addDisplay = false"></app-invite>
		</div>

		<span *ngIf="!org.addDisplay">
			<ng-container *ngIf="userHasOrgPermission(org, 'process')">
				<div *featureEnabled="'!disable-invites'" [ngClass]="{'hidden': org.addDisplay}"
					class="H7Blue500Regular" style="display: flex; margin: 16px; cursor: pointer;"
					(click)="org.addDisplay = !org.addDisplay">
					<mat-icon style="margin-right: 8px;">add_circle_outline</mat-icon>Add member(s)
				</div>
				<mat-divider color="#dee0e3"></mat-divider>
			</ng-container>
			<div class="user-container" *ngFor="let orgUser of org.users; let i = index">
				<aerial-avatar style="margin-right: 16px;">{{(orgUser.first_name ? orgUser.first_name[0] : '') + '' +
					(orgUser.last_name ? orgUser.last_name[0] : '')}}</aerial-avatar>
				<div class="name-container">
					<div class="P1Black700 ellipsis">{{orgUser.first_name + ' ' + orgUser.last_name}} {{orgUser.id ===
						user.id ? '(You)' : ''}}</div>
					<div class="P1Black500 ellipsis">{{orgUser.email}}</div>
				</div>
				<div class="row-options">
					<ng-container *ngIf="!isMobile">
						<ng-container
							*ngTemplateOutlet="t_options context: {orgUser: orgUser, org: org}"></ng-container>
					</ng-container>
					<ng-container *ngIf="isMobile">
						<ng-container
							*ngTemplateOutlet="t_mobileOptions context: {orgUser: orgUser, org: org}"></ng-container>
					</ng-container>
				</div>
			</div>
		</span>
	</mat-expansion-panel>
</ng-template>

<ng-template #t_options let-orgUser="orgUser" let-org="org">
	<ng-container *ngIf="userHasOrgPermission(org, 'process')">
		<span *featureEnabled="'!disable-modify-permissions'" class="P1Black500">
			<mat-form-field appearance="fill">
				<mat-select (selectionChange)="updateUserRole($event, org, orgUser)" [(value)]="orgUser.role"
					[disabled]="orgUser.disabled" data-testid="permission-selector">
					<ng-container *ngFor="let role of permissionList">
						<mat-option *ngIf="orgUser.disabled || userHasOrgPermission(org, role.value)" [value]="role">
							{{role.text}}
						</mat-option>
					</ng-container>
				</mat-select>
			</mat-form-field>
		</span>
		<span *featureEnabled="'!disable-remove-user'" [ngClass]="{'disabled': !userHasOrgPermission(org, 'admin')}"
			class="remove-user-button">
			<button mat-icon-button matTooltip="Remove" (click)="removeUserFromTeam(org, orgUser)">
				<mat-icon>delete_outline</mat-icon>
			</button>
		</span>
	</ng-container>
	<span class="P1Black700" *ngIf="!userHasOrgPermission(org, 'process')">
		{{ orgUser.role.text }}
	</span>
</ng-template>

<ng-template #t_mobileOptions let-orgUser="orgUser" let-org="org">
	<ng-container *ngIf="userHasOrgPermission(org, 'process')">
		<button mat-icon-button [matMenuTriggerFor]="optionsMenu" class="more-options"
			(click)="$event.stopPropagation();">
			<mat-icon>more_horiz</mat-icon>
		</button>
		<mat-menu #optionsMenu="matMenu" class="little-menu centered-menu-text">
			<ng-template matMenuContent>
				<mat-list>
					<mat-list-item>
						<span *featureEnabled="'!disable-modify-permissions'" class="modify-permissions">
							<mat-select (selectionChange)="updateUserRole($event, org, orgUser)"
								[(value)]="orgUser.role" *ngIf="userHasOrgPermission(org, 'process')"
								data-testid="permission-selector">
								<mat-option *ngFor="let role of permissionList"
									[disabled]="!userHasOrgPermission(org, role)" [value]="role.value">
									{{role.text}}
								</mat-option>
							</mat-select>
						</span>
					</mat-list-item>
					<mat-list-item>
						<span *featureEnabled="'!disable-remove-user'" class="remove-user-button">
							Remove user
							<button mat-icon-button matTooltip="Remove" (click)="removeUserFromTeam(org, orgUser)"
								[disabled]="!userHasOrgPermission(org, 'admin')">
								<mat-icon>delete_outline</mat-icon>
							</button>
						</span>
					</mat-list-item>
				</mat-list>
			</ng-template>
		</mat-menu>
	</ng-container>
</ng-template>


<ng-template #t_createTeam>
	<div class="back-container">
		<span class="H3Black700" style="display: flex;">
			<button *ngIf="orgList && orgList.length" matTooltip="Back" mat-button style="margin: auto 24px auto 0;"
				(click)="handleBack();"><mat-icon style="font-size: 24px;">keyboard_backspace</mat-icon></button>
			<span class="H4Black700Regular"> Create Team </span>
		</span>
	</div>

	<div class="text-container">
		<span class="H7Black500Regular" style="min-width: 208px;">Basic info:</span>
		<form [formGroup]="teamForm" style="width: 100%;">
			<div class="list-name">Team name</div>
			<mat-form-field appearance="fill" style="width: 100%; margin: 8px 0px;">
				<input matInput formControlName="name" placeholder="Enter name" autofocus>
			</mat-form-field>
		</form>
	</div>


	<div class="text-container">
		<div class="reverse-actions">
			<button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="teamForm.invalid"> Create
				Team</button>
			<button mat-stroked-button color="primary" (click)="handleBack();">Cancel</button>
		</div>
	</div>

</ng-template>
