
/* Imports */
import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter, Inject } from '@angular/core';
import { AuthenticationService, SessionService, TitleService, UserService } from '../../../shared/services';
import { analyticsLayer } from '@shared/analyticsLayer';

@Component({
	selector: 'app-account-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

	@Input('user') user: any;

	public notify_email: boolean = false;
	public user_notifications: any = {
		photos_uploaded: false, // does not currently exisit
		processed_model: false, //this happens everytime on the BE right now
		team_request: false, // does not currently exist
		storage_limit: false, // does not currently exist
		processing_credits_low: false, //does not currently exist
		purchase_credits_storage: false, //does not currently exist
		newsletter: false, //not current option in html
	};

	constructor(
		private _authService: AuthenticationService,
		private _userService: UserService,
		private _titleService: TitleService
	) {
		if (this._authService.user.descriptors.user_notifications) {
			this.user_notifications = this._authService.user.descriptors.user_notifications;
			this.notify_email = Object.keys(this.user_notifications).some(x => this.user_notifications[x]);
		}
	}

	ngOnInit() {

		this._titleService.setTitle('Alerts & notifications');
		analyticsLayer.trackPage();

	}	// End-of ngOnInit

	notificationChange(): void {
		this._authService.user.descriptors.user_notifications = this.user_notifications;
		let user = this.user;
		this._userService.update(user).then(rtn => {
			SessionService.set('user', user);
		}, err => { console.error(err); });
	}

	onToggle(): void {
		setTimeout(() => {
			if (!this.notify_email) {
				Object.keys(this.user_notifications).forEach(item => { this.user_notifications[item] = false; });
				this.notificationChange();
			}
		});
	}

}	// End-of class TestComponent
