
<div class="error">
	These settings may not be changed at this time. Please check back as we add more alerts and customization options!
</div>


<div class="H6Black700Regular" style="display: flex; margin-top: 24px;">
	<span>Turn on email notification</span>
	<!--
		The only current notification sends all the time anyways, so this is set diabled for now
		<mat-slide-toggle (toggleChange)="onToggle();" style="margin-left: auto;" [(ngModel)]="notify_email">
	-->
	<mat-slide-toggle [disabled]="true" [checked]="true" style="margin-left: auto;">
	</mat-slide-toggle>
</div>
<div class="H7Black500Regular">Send email notifications to: {{user.email}}</div>

<div class="LabelBlack500 uppercase" style="margin-top: 48px;">Send email notifications when:</div>

<!-- <div class="H6Black700Regular" style="display: flex; margin-top: 24px;">
	<span>Photos and data have been fully uploaded to the project that I have created.</span>
	<mat-slide-toggle style="margin-left: auto;" [disabled]="!notify_email" (change)="notificationChange();"
		[(ngModel)]="user_notifications.photos_uploaded"></mat-slide-toggle>
</div> -->

<div class="H6Black700Regular" style="display: flex; margin-top: 24px;">
	<span>My model(s) has been fully processed.</span>
	<!-- <mat-slide-toggle style="margin-left: auto;" [disabled]="!notify_email" (change)="notificationChange();"
		[(ngModel)]="user_notifications.processed_model"></mat-slide-toggle> -->
	<mat-slide-toggle style="margin-left: auto;" [disabled]="true" [checked]="true"></mat-slide-toggle>
</div>

<!-- <div class="H6Black700Regular" style="display: flex; margin-top: 24px;">
	<span>Team member requests to process a model.</span>
	<mat-slide-toggle style="margin-left: auto;" [disabled]="!notify_email" (change)="notificationChange();"
		[(ngModel)]="user_notifications.team_request"></mat-slide-toggle>
</div> -->

<!-- <div class="H6Black700Regular" style="display: flex; margin-top: 24px;">
	<span>Reaching storage limit.</span>
	<mat-slide-toggle style="margin-left: auto;" [disabled]="!notify_email" (change)="notificationChange();"
		[(ngModel)]="user_notifications.storage_limit"></mat-slide-toggle>
</div> -->

<!-- <div class="H6Black700Regular" style="display: flex; margin-top: 24px;">
	<span>Running low on processing credits.</span>
	<mat-slide-toggle style="margin-left: auto;" [disabled]="!notify_email" (change)="notificationChange();"
		[(ngModel)]="user_notifications.processing_credits_low"></mat-slide-toggle>
</div> -->

<!-- <div class="H6Black700Regular" style="display: flex; margin-top: 24px;">
	<span>Me or my team buys additional credits / storage.</span>
	<mat-slide-toggle style="margin-left: auto;" [disabled]="!notify_email" (change)="notificationChange();"
		[(ngModel)]="user_notifications.purchase_credits_storage"></mat-slide-toggle>
</div> -->

<!-- <div class="LabelBlack500 uppercase" style="margin-top: 48px;">Newsletter</div>

<div class="H6Black700Regular" style="display: flex; margin-top: 24px;">
	<span>Subscribe to Aerial Applications newsletter</span>
	<mat-slide-toggle style="margin-left: auto;" [disabled]="!notify_email" (change)="notificationChange();"
		[(ngModel)]="user_notifications.newsletter"></mat-slide-toggle>
</div>
<div class="H7Black500Regular">Get the latest up-to-date information on Mapware and other products released by Aerial
	Applications.</div> -->
