<div style="margin-top: 32px;">
	<div class="group-container">
		<div class="body">
			<div *ngIf="showSubHeader" class="H7Black500Regular">Enter your card information:</div>
			<div class="ChargeBeeElement"
				cbCardField id='card-field'
				(change)="cbIsCardComplete($event)"
				(ready)="setComponent($event)">
			</div>
			<div class="H7RedRegular" *ngIf="cbError">{{cbError}}</div>
		</div>
	</div>
</div>

<div class="confirmation-group" style="border-bottom: none;">
	<div class="group-container">
		<span class="card-buttons">
			<button mat-stroked-button color="primary" (click)="goBack()">{{backButtonText}}</button>
			<button mat-stroked-button color="primary" [disabled]="!cbValidCard"
				(click)="onSubmit()">{{nextButtonText}}</button>
		</span>
	</div>
</div>
