
/* Imports */
import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { AlertService, AuthenticationService, OrganizationService, FinanceService } from '../../../../shared/services';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-chargebee-card-input',
	templateUrl: './chargebeecard.component.html',
	styleUrls: ['./chargebeecard.component.scss']
})
export class ChargebeeCardComponent implements OnInit, AfterViewInit, AfterViewInit, OnDestroy {

	@Input('showSubHeader') showSubHeader: boolean = false;
	@Input('nextButtonText') nextButtonText: string;
	@Input('backButtonText') backButtonText: string;
	@Output('back') backEvent: EventEmitter<boolean> = new EventEmitter();
	@Output('onCardSuccess') cardSuccessEvent: EventEmitter<string> = new EventEmitter();
	@Output('onCardFail') cardFailEvent: EventEmitter<boolean> = new EventEmitter();

	public cbValidCard: boolean = false;
	public cbError: string;

	private cardComponent: any;

	constructor (
		public dialog: MatDialog,
		private _authService: AuthenticationService,
		private _alertService: AlertService,
		private _orgService: OrganizationService,
		private _financeService: FinanceService,
		private _changeDetectorRef: ChangeDetectorRef,
		private _cdr: ChangeDetectorRef,
	) {
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
	}

	ngOnDestroy() {
	}

	onChange({ error }) {
		console.error(error)
		if (error) {
		} else {
		}
		this._cdr.detectChanges();
	}

	async onSubmit() {
		this.tokenize();
	}

	goBack(): void {
		this.backEvent.emit(true);
	}

	tokenize() {
		// Use card component instance to call the tokenize method
		this.cardComponent.tokenize().then((cbData) => {
			this.cbError = '';
			this.cardSuccessEvent?.emit(cbData.token);
		}).catch((error) => {
			this.cbError = "Problem validating your card\'s details";
			this.cardFailEvent?.emit(true);
		})
	}

	// Receive the cardComponent instance on ready event
	setComponent(cardComponent) {
		// @ts-ignore
		this.cardComponent = cardComponent;
	}

	cbIsCardComplete = (currentState) => {
		this.cbValidCard = currentState.complete;
		this._changeDetectorRef.detectChanges();
		// Added to ensure Angular updates the html variables
		// It's not an instantaneous check, Angular's lifecycle checks the changeDetector roughly once every 2-3 seconds,
		// hence why sometimes the button would time to update. detectChanges triggers it immediately, updating the button status
	}
}
