
<div class="profile">
	<ng-container *ngIf="(orgList && orgList.length) && !isLoading">

		<div class="tablet-hidden">
			<ng-container *ngTemplateOutlet="t_accountMenu"></ng-container>
		</div>

		<mat-menu class="logout-menu" #logoutMenu>
			<ng-container *ngTemplateOutlet="t_accountMenu"></ng-container>
		</mat-menu>

		<div class="content" #content [ngSwitch]="currentProfilePage">

			<div class="page-title">
				<div class="title-text">
					<button mat-button class="tablet-visible" [matMenuTriggerFor]="logoutMenu">
						<mat-icon style="font-size: 24px;"> menu </mat-icon>
					</button>
					{{currentProfilePage.name}}
				</div>
				<div class="sub-title-text" *ngIf="currentProfilePage.subText">{{currentProfilePage.subText}}</div>
			</div>

			<mat-divider></mat-divider>

			<div class="page-container">

				<ng-container *ngSwitchCase="profileItems['profile']">
					<app-account-profile [user]="user" [orgList]="orgList" (navToTeams)="currentProfilePage = profileItems['teams']"></app-account-profile>
				</ng-container>

				<ng-container *ngSwitchCase="profileItems['plan']">
					<app-account-plan
						[user]="user"
						[orgList]="orgList"
						[selectedOrg]="selectedOrg"
						[addNewCard]="addNewCard"
						(fullPage)="toggleFullpage($event);"
						(updateOrgList)="getOrgList()"
						(freeToPaid)="closeModal($event);">
					</app-account-plan>
				</ng-container>

				<ng-container *ngSwitchCase="profileItems['teams']">
					<app-account-teams [user]="user" [orgList]="orgList" (updateList)="getOrgList()" (closeModal)="closeModal()"></app-account-teams>
				</ng-container>

				<ng-container *ngSwitchCase="profileItems['receipt']">
					<app-account-receipt [user]="user" [orgList]="orgList"></app-account-receipt>
				</ng-container>

				<ng-container *ngSwitchCase="profileItems['alert']">
					<app-account-notification [user]="user"></app-account-notification>
				</ng-container>

				<button mat-button class="close-button" matTooltip="Close" (click)="closeModal()" data-testid="account-menu-close">
					<mat-icon inline="true">close</mat-icon>
				</button>
			</div>
		</div>
	</ng-container>
</div>
<span *ngIf="!(orgList && orgList.length) && !isLoading" style="display: flex; position: relative; background-color: white;">
	<div class="H4Black500Regular" style="margin: 96px; ;">
		<div>You do not have any Organizations.</div>
		<div style="margin-bottom: 32px;">
			<span class="link" class="text-link" (click)="sendFeedback();">Click here</span>
			<span class="H7Black500Regular"> to contact support to add an organization.</span>
		</div>
	</div>
	<button mat-button class="close-button" matTooltip="Close" mat-button matDialogClose><mat-icon>close</mat-icon></button>
</span>

<ng-template #t_accountMenu>
	<span class="sidebar">
		<ng-container *ngFor="let page of getObjectValues(profileItems)">
			<span class="sidebar-button"
				  (click)="currentProfilePage = page"
				  [ngClass]="{'active': currentProfilePage === page, 'disabled': !(currentProfilePage === page || (orgList?.length))}"
				  attr.data-testid="list-item-{{page.value}}">
						{{page.name}}
			</span>
		</ng-container>
	</span>
</ng-template>
