
/* Imports */
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import moment from 'moment'; // this is required because of how `moment` exports its object

/* Services */
import { OrganizationService, TitleService, UtilsService } from '../../../shared/services';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from "@angular/material/sort";
import { analyticsLayer } from '@shared/analyticsLayer';
import { PermissionService } from '../../../shared/services/permission.service';

@Component({
	selector: 'app-account-receipt',
	templateUrl: './receipt.component.html',
	styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {

	@Input('user') user: any;
	@Input('orgList') orgList: Array<any> = [];
	//@ViewChild(MatSort) sort: MatSort;
	@ViewChild(MatSort) set sort(s: MatSort) {
		if (s) { this.displayReceipts.sort = s; }
	};

	public receiptColumns = {
		mobile: [
			'created_at',
			'quantity'
		],
		desktop: [
			'created_at',
			'quantity',
			'balance'
		]
	}

	public isLoading: boolean = false;
	public displayedColumns: string[] = this.receiptColumns.desktop;

	public periodOptions: Array<any> = [
		{ text: '1 week', value: '1 week' },
		{ text: '1 month', value: '1 month' },
		{ text: '3 months', value: '3 month' },
		{ text: '6 months', value: '6 month' },
		{ text: '1 year', value: '1 year' },
		{ text: 'Complete history', value: 'all' },
	];

	public selectedTeam: any = null;
	public receiptData: Array<any>;
	public displayReceipts: MatTableDataSource<any> = new MatTableDataSource<any>();
	public isMobile: boolean = false;
	public showTeamReceipts: boolean = false;

	constructor(
		private _orgService: OrganizationService,
		private _titleService: TitleService,
		private _utilsService: UtilsService,
		private _permissionService: PermissionService,
	) { }

	ngOnInit() {

		this._titleService.setTitle('Processing receipts');
		analyticsLayer.trackPage();
		if (this.orgList.length === 1) {
			this.selectTeam(this.orgList[0]);
		}
		this.isMobile = this._utilsService.getIsMobile();

		this._utilsService.getScreenResize().subscribe((isMobile) => {
			this.displayedColumns = isMobile ? this.receiptColumns.mobile : this.receiptColumns.desktop;
			this.isMobile = isMobile;
		})

	}	// End-of ngOnInit

	selectTeam(team): void {
		this._permissionService.getUserPermissions(team).then(rtnPermissions => {
			this.showTeamReceipts = rtnPermissions["admin"];
		}).catch(err => {
			console.error(err);
			this.showTeamReceipts = false;
		})
		this.isLoading = true;
		this.selectedTeam = team;
		this._orgService.getProcessingCreditEvents(team).then(rtn => {
			this.receiptData = rtn;
			this.filterReceipts(this.periodOptions[0] as MatSelectChange);
		}).catch(err => {
			console.error(err);
			this.isLoading = false;
		});
	}

	goBack(): void {
		this.selectedTeam = null;
	}

	toggleOpen(row): void {
		row.expanded = !row.expanded;
	}

	sendFeedback(): void {
		UtilsService.sendSupportEmail();
	}

	filterReceipts({ value }: MatSelectChange): void {
		const [amount, period] = value.split(' ');

		if (amount === 'all') {
			this.displayReceipts.data = this.receiptData;
		} else {
			const momentFilter = moment()
				.startOf('day')
				.subtract(Number(amount), period);

			this.displayReceipts.data = this.receiptData.filter(r =>
				moment(r.updated_at).isAfter(momentFilter)
			);
		}
		this.isLoading = false;
	}

	abs(num: number): number {
		return Math.abs(num);
	}

}	// End-of class TestComponent
