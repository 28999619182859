
<div class="confirmation-group">
	<div class="profile-container">
		<span class="profile-name-container">
			<div class="H3Black700">{{user.first_name + ' ' + user.last_name}}</div>
			<div class="H6Black500Regular">You are in <span class="text-link" (click)="goToTeams()">{{orgList.length}} {{orgList.length === 1 ? 'team' : 'teams'}}</span>.</div>
		</span>
	</div>
</div>

<div class="confirmation-group">
	<div class="group-container">
		<div class="header-group">
			<div class="header">Login credentials:</div>
			<span class="H7Black700Semibold">{{user.email}}</span>
		</div>
		<!-- <div class="H7Black500Regular">
			<div>Need to unlink your account and use another email?</div>
			<div>Send your request to: info@aerialapplications.com</div>
		</div> -->
	</div>
</div>

<div class="confirmation-group">
	<div class="group-container">
		<div class="header-group">
			<span class="H7Black500Regular" class="header">Password:</span>
			<span *ngIf="!editPassword">●●●●●●●●●●</span>
		</div>
		<span *ngIf="!editPassword" class="tool" (click)="editPassword = true">Change password</span>
		<span *ngIf="editPassword">
			<form class="main-form"
				[formGroup]="resetPasswordForm"
				novalidate>
				<div style="margin-top: 4px;">
					<div class="flex-between">
						<div class="H7Black700Regular" style="margin-right: 24px;">New password</div>
						<div class="P2Black500">(Must be at least 8 characters long)</div>
					</div>
					<mat-form-field appearance="fill" class="fill-width">
						<input matInput
							[type]="showPassword ? 'text' : 'password'"
							data-testid="password-field"
							placeholder="Enter password"
						    autocomplete="new-password"
							formControlName="password">
					</mat-form-field>
				</div>
				<div style="margin-top: 3rem;">
					<div class="H7Black700Regular" style="margin-right: 24px;">Confirm password</div>
					<mat-form-field appearance="fill" class="fill-width">
						<input matInput
							[type]="showPassword ? 'text' : 'password'"
							data-testid="confirm-password-field"
							placeholder="Enter password"
							autocomplete="new-password"
							formControlName="confirm_password">
					</mat-form-field>
					<div class="show-password">
						<mat-checkbox (click)="showPassword = !showPassword"></mat-checkbox>
						Show Password
					</div>
				</div>
			</form>
			<div class="change-password-buttons">
				<button mat-stroked-button color="primary" [disabled]="resetPasswordForm.invalid" (click)="onResetPasswordSubmit()" style="margin-right: 16px;">Change password</button>
				<span class="link" (click)="editPassword = false">Cancel</span>
			</div>
			<mat-error *ngIf="editPasswordError">{{editPasswordError}}</mat-error>
		</span>
	</div>
</div>

<div class="confirmation-group">
	<div class="group-container">
		<ng-container *ngIf="!editPhone">
			<div class="header-group">
				<span class="header">Phone number:</span>
				<span>{{user.phone ? (user.phone | phone) : '- -'}}</span>
			</div>
			<span class="tool" style="margin-left: auto;" (click)="editPhone = true;">{{user.phone ? 'Change number' : 'Add number'}}</span>
		</ng-container>

		<span *ngIf="editPhone" class="body">
			<span class="header">Phone number:</span>
			<form class="main-form"
				[formGroup]="userForm"
				novalidate>
				<div style="margin-top: 4px;">
					<mat-form-field appearance="fill">
						<input matInput
							mask="(000) 000-0000"
							placeholder="Enter phone number"
							formControlName="phone">
					</mat-form-field>
				</div>
			</form>
			<div style="margin-top: 24px;">
				<button mat-flat-button [disabled]="userForm.invalid" (click)="updateUser()" style="margin-right: 16px;">{{user.phone ? 'Update number' : 'Add number'}}</button>
				<span class="link" (click)="editPhone = false">Cancel</span>
			</div>
		</span>
	</div>
</div>

<!-- <mat-divider color="rgba(0, 0, 0, 0.47)"></mat-divider>

<div style="margin: 32px 0; display: flex;">
	<div class="uppercase LabelBlack700">Additional Information</div>
</div> -->
