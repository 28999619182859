<div *ngIf="showInvitations; else inviteBlock">
	<span>
		<div class="H5Black700" style="display: flex;">
			<button matTooltip="Back" mat-button style="margin: auto 18px auto 0;"
				(click)="showInvitations=false;"><mat-icon
					style="font-size: 20px;">keyboard_backspace</mat-icon></button>
			<div class="P1Black500">({{invitations.length}}) INVITES TOTAL</div>
		</div>
	</span>

	<div style="margin-top: 16px; border-top: solid 1px rgba(0,0,0,.23);">
		<div style="display: flex; margin: 16px;" *ngFor="let invite of invitations;">
			<span>
				<div class="P1Black700">{{invite.email}}</div>
				<div class="P1Black500">Invite created: {{invite.created_at ? (invite.created_at | date) : 'Never'}}
				</div>
			</span>
			<span style="width: auto; margin: auto 16px auto auto;">
				<button data-testid="delete-invitation" mat-icon-button matTooltip="Remove"
					[disabled]="!userCanDeleteInvite(invite)" (click)="confirmRemoveInvite(invite)">
					<mat-icon>delete_outline</mat-icon>
				</button>
			</span>
		</div>
	</div>
</div>

<ng-template #inviteBlock>
	<div class="MuliBold uppercase" style="margin-bottom: 8px;">Invite Members</div>
	<div class="invite-container">
		<mat-form-field appearance="fill" class="name-field">
			<input data-testid="invite-input" type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i" matInput
				placeholder="+ Enter email address" (keydown.enter)="sendInvite()"
				(ngModelChange)="displayError = null;" [(ngModel)]="emailInput">
		</mat-form-field>
		<mat-form-field appearance="outline">
			<mat-select primary [(value)]="userPermissions">
				<ng-container *ngFor="let role of permissionList">
					<mat-option *ngIf="userHasRolePermission(role)" [value]="role.value">
						{{role.text}}
					</mat-option>
				</ng-container>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="H7Blue500Regular" *ngIf="!displayError" style="display: flex; margin: 16px; cursor: pointer;"
		(click)="showInvitations = true">{{invitations.length}}+ Pending Invitations</div>
	<div class="P1Red" style="width: 100%; margin-top: 8px;" *ngIf="displayError">{{displayError}}</div>
	<div class="invite-actions">
		<button mat-stroked-button color="primary" style="margin: auto 32px auto auto;" (click)="cancel()">{{inviteSent
			? 'Back' : 'Cancel'}}</button>
		<button mat-stroked-button color="primary" [disabled]="!checkEmail(emailInput)" (click)="sendInvite()">Send
			invite</button>
	</div>
</ng-template>