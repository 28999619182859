<ng-container *ngIf="!selectedTeam">
	<ng-container *ngTemplateOutlet="t_showList"></ng-container>
</ng-container>
<ng-container *ngIf="selectedTeam && !isLoading">
	<ng-container *ngTemplateOutlet="t_showReceipt"></ng-container>
</ng-container>

<ng-container *ngIf="isLoading">
	<div class="receipt-loading">
		<div class="dot-stretching"></div>
	</div>
</ng-container>

<ng-template #t_showList>

	<div class="LabelBlack500 uppercase" style="margin: 32px 0 16px 0;">
		{{orgList.length}} Processing Receipts Teams
	</div>

	<mat-divider color="#00000026"></mat-divider>

	<mat-list style="padding: 0;">
		<mat-list-item *ngFor="let team of orgList" (click)="selectTeam(team);">
			<span class="list-name"> {{team.name}} </span>
			<span class="H6Black500Regular" style="margin:auto;">{{team.processing_credit_events_count}}
				{{team.processing_credit_events_count === 1 ? 'receipt' : 'receipts'}}</span>
			<mat-icon style="margin: auto 0 auto auto; font-size: 32px;">chevron_right</mat-icon>
		</mat-list-item>
	</mat-list>

	<div class="information" *ngIf="orgList.length > 1">
		<span style="margin-right: 8px;">
			<mat-icon>help_outline</mat-icon>
		</span>
		<span>
			<div class="H7Black700Regular" style="margin-bottom: 8px;">Why am I seeing multiple team receipts?</div>
			<div class="H7Black700Semibold">
				If you are added to a team as an ‘Admin’, you have access to the team’s
				processing receipts.  You can change your access to ‘Creator’ or 'Viewer' in a team if you do not want to access to the teams’ processing receipts.
			</div>
		</span>
	</div>
</ng-template>


<ng-template #t_showReceipt>
	<div class="back-container">
		<div class="back-inner">
			<button mat-icon-button (click)="goBack()" style="margin-right: 8px;" *ngIf="selectedTeam && orgList.length > 1">
				<mat-icon style="font-size: 24px;">keyboard_backspace</mat-icon>
			</button>
			<div class="H3Black700">{{selectedTeam.name}}</div>
		</div>
	</div>

	<ng-container *ngIf="receiptData?.length && showTeamReceipts">
		<div class="H7Black700Regular" style="margin: 32px 0 8px 0;">Show processing credit history during the last:
		</div>
		<mat-form-field appearance="outline" class="history-select">
			<mat-select class="receipt-select" data-testid="receipt-select" [value]="periodOptions[0].value"
				(selectionChange)="filterReceipts($event)">
				<mat-option *ngFor="let period of periodOptions" [value]="period.value">
					{{ period.text }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-table [dataSource]="displayReceipts" matSort style="margin-top: 24px;">
			<!-- File name Column -->
			<ng-container matColumnDef="created_at">
				<mat-header-cell *matHeaderCellDef mat-sort-header disableClear
					class="LabelBlack500 uppercase name-cell">
					Date
				</mat-header-cell>
				<mat-cell *matCellDef="let row" class="name-cell">

					<span style="margin-right: 16px;">
						<mat-icon style="color: #6a7483;">{{row.type === 'purchase' ? 'toll' : 'assignment'}}
						</mat-icon>
					</span>

					<span style="display: block;" class="P1Black700">

						<div>{{row.notes ? row.notes : '- -'}}</div>

						<div class="CenterP2Black500" style="text-align: left;">
							{{row.created_at ? (row.created_at | date) : '- -'}}
						</div>

						<ng-container *ngIf="row.expanded">
							<div *ngIf="row.type === 'use'"
								style="display: block; margin-top: 24px; overflow-wrap: normal;">
								<span style="width: 142px; margin-right: 24px; margin-bottom: 8px;"
									class="H7Black500Regular">Total number of images processed:</span>
								<span>{{row.photos_processed}}</span>
							</div>
						</ng-container>
					</span>
				</mat-cell>

			</ng-container>

			<ng-container *ngIf="isMobile">
				<!-- File name Column -->
				<ng-container matColumnDef="quantity">
					<mat-header-cell *matHeaderCellDef arrowPosition="before" mat-sort-header disableClear
						class="LabelBlack500 uppercase credits-cell">
						<div class="right-text">
							<div>Used/Purchased</div>
							<div>(Remaining Credits)</div>
						</div>
					</mat-header-cell>
					<mat-cell *matCellDef="let row" class="credits-cell">
						<div class="right-text">
							<div>{{abs(row.quantity) | comma}}</div>
							<div>({{(row.balance + row.subscription_balance) | comma}})</div>
						</div>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="balance"></ng-container>
			</ng-container>

			<ng-container *ngIf="!isMobile">
				<!-- File name Column -->
				<ng-container matColumnDef="quantity">
					<mat-header-cell *matHeaderCellDef mat-sort-header disableClear
						class="LabelBlack500 uppercase credits-cell">
						Used/Purchased
					</mat-header-cell>
					<mat-cell *matCellDef="let row" class="credits-cell">
						{{abs(row.quantity) | comma}}
					</mat-cell>
				</ng-container>

				<!-- Status Column -->
				<ng-container matColumnDef="balance">
					<mat-header-cell *matHeaderCellDef mat-sort-header disableClear
						class="LabelBlack500 uppercase credits-cell">
						Remaining Credits
					</mat-header-cell>

					<mat-cell *matCellDef="let row" class="credits-cell">
						{{(row.balance + row.subscription_balance) | comma}}
					</mat-cell>
				</ng-container>
			</ng-container>

			<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
			<mat-row [ngClass]="{'expanded': row.expanded}" (click)="toggleOpen(row)"
				*matRowDef="let row; columns: displayedColumns;"></mat-row>
		</mat-table>
	</ng-container>

	<div class="information" *ngIf="!displayReceipts.data?.length || !showTeamReceipts">
		<ng-container *ngIf="showTeamReceipts">
			<span style="margin-right: 8px;">
				<mat-icon>help_outline</mat-icon>
			</span>
			<span *ngIf="!receiptData?.length">
				<div class="H7Black700Regular" style="margin-bottom: 16px;">Why are there no receipts here?</div>
				<div class="H7Black700Semibold">
					Your team has not processed any models yet. Processing receipts will appear here once a model has
					finished processing. If you believe this is a mistake, please
					<span class="link" (click)="sendFeedback();">contact support</span>.
				</div>
			</span>
			<span *ngIf="receiptData?.length">
				<div class="H7Black700Regular" style="margin-bottom: 16px;">No Processing Receipts in the selected
					timeframe.</div>
				<div class="H7Black700Semibold">
					Please choose another timeframe to see your processing credit history.
				</div>
			</span>
		</ng-container>
		<ng-container *ngIf="!showTeamReceipts">
			<span style="margin-right: 8px;">
				<mat-icon>help_outline</mat-icon>
			</span>
			<span>
				<div class="H7Black700Regular" style="margin-bottom: 16px;">Why are there no receipts here?</div>
				<div class="H7Black700Semibold">
					Only Admins can access team processing receipts. If you are an admin and seeing this in error,
					please
					<span class="link" (click)="sendFeedback();">contact support</span>.
				</div>
			</span>
		</ng-container>
	</div>
</ng-template>
