/* Imports */
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
	AuthenticationService,
	AlertService,
	OrganizationService,
	PermissionService,
	isUniqueName
} from '../../shared/services';
import { USERROLES, Alert } from '../../shared/models';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModal } from "@app/components";

@Component({
	selector: 'app-invite',
	templateUrl: './invite.component.html',
	styleUrls: ['./invite.component.scss']
})
export class InviteComponent implements OnInit {

	@Input('selectedOrg') selectedOrg: any;
	@Input('orgPermissions') orgPermissions: any;
	@Output('cancel') cancelEvent: EventEmitter<boolean> = new EventEmitter();

	public user: any;
	public emailInput: string = '';
	public displayedColumns: string[] = ['email', 'role', 'trash'];
	public displayError: string = null;

	public showInvitations: boolean = false;
	public invitations: Array<any> = [];
	public permissionList: Array<any> = [];
	public userPermissions: any;
	public inviteSent: boolean = false;

	constructor(
		private _dialog: MatDialog,
		private _authService: AuthenticationService,
		private _alertService: AlertService,
		private _orgService: OrganizationService,
		private _permissionService: PermissionService,
		private _formBuilder: FormBuilder,
		private _cdr: ChangeDetectorRef,
	) {
		this.user = this._authService.user;
		this.permissionList = this._permissionService.getPermissionList();
		this.userPermissions = this.permissionList[this.permissionList.length - 1].value;
	}

	ngOnInit() {
		this.updateInviteList();
	}	// End-of ngOnInit

	updateInviteList(): void {
		this._orgService.getInvitationList(this.selectedOrg).then(rtnList => {
			rtnList.forEach(invite => {
				invite.created_at = new Date(invite.created_at);
				invite.updated_at = new Date(invite.updated_at);
			})
			this.invitations = rtnList;
		})
	}

	sendInvite(): void {
		const org = this.selectedOrg;
		const activeSub = org?.subscription?.status === "active";
		const existingNames = org.users?.map(user => user.email) ?? [];
		const isUnique = isUniqueName(existingNames)(this.emailInput);

		if (this.checkEmail(this.emailInput) && activeSub && isUnique) {
			this.createAndSendInvite(org)
		} else {
			if (!this.checkEmail(this.emailInput)) {
				this.displayError = 'This is not a valid email address. Please check what has been entered.';
			} else if (!isUnique) {
				this.displayError = 'This email address is already a member of the team.';
			} else {
				this.displayError = 'This team does not have an active subscription.';
			}

		}
	}

	createAndSendInvite(org) {
		this._orgService.createUser(org, this.emailInput, USERROLES[this.userPermissions]).then(rtn => {
			this.displayError = null;
			this.updateInviteList();
			this._alertService.success(new Alert(`Invitation to ${this.emailInput} was sent!`));
			this.emailInput = '';
			this._cdr.detectChanges();
			this.inviteSent = true;
		}, err => {
			if (err === 412) {
				const isTeam = org.subscription?.plan_id.includes("team");
				this.displayError = `You've reached the maximum number of team members with your current subscription plan. To invite more people, please upgrade to a ${!isTeam ? "Team or" : ""} Fleet subscription.`;
			} else {
				this.displayError = 'This email is already registered or not a valid email. Please try again.';
			}
			console.error(err)
		})
	}

	confirmRemoveInvite(invite): void {
		let confirmString: string = `Do you want to uninvite ${invite.email} from the project?`;
		let dialogRef = this._dialog.open(ConfirmationModal, {
			data:
			{
				title: 'Uninvite from Project?',
				text: confirmString,
			}
		});

		dialogRef.afterClosed().subscribe((rtn) => {
			if (rtn) {
				this.removeInviteFromTeam(invite);
			}
		});
	}

	removeInviteFromTeam(invite) {
		-		this._orgService.removeInvitation(this.selectedOrg, invite.id).then(rtnData => {
			-			this.updateInviteList();
			this._alertService.success(new Alert('Invitation Deleted!'));
		})
	}

	userCanDeleteInvite(invite): boolean {
		const user = this.selectedOrg.users.find(x => x.id === this.user.id);
		return (user?.role === 'admin' || invite.created_by_id === user?.id);
	}

	displayRole(inRole: string): string {
		let roleItem = this.permissionList.find(role => role.value == inRole);
		if (roleItem && roleItem.text) {
			return roleItem.text;
		} else {
			return 'View';
		}
	}

	cancel(): void {
		this.cancelEvent.emit();
	}

	checkEmail(email): boolean {
		return (/\S+@\S+\.\S+/.test(email));
	}

	userHasRolePermission(role) {
		return this.orgPermissions[role?.value ?? 0] ?? false;
	}
}
