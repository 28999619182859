import { Component, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
	ProjectService, AlertService
} from '@shared/services';
import { TagsComponent } from 'src/app/components';
import { Organization, User } from "@shared/models";


@Component({
	selector: 'app-plan-dialog',
	templateUrl: "./plan.dialog.html",
	styleUrls: ["./plan.component.scss"],
})
export class PlanDialog {

	@ViewChild(TagsComponent) tagComp: TagsComponent;

	public user: User;
	public orgList: Organization[];
	public selectedOrg: Organization;

	constructor(
		public dialog: MatDialog,
		private _alertService: AlertService,
		private _projectService: ProjectService,
		public _dialogRef: MatDialogRef<PlanDialog>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		Object.assign(this, data);
	}

	closeDialog() {
		this._dialogRef.close(true);
	}

}
