
/* Imports */
import { Component, Input, OnInit, OnDestroy, NgZone, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized, RouterModule, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

/* Services */
import { AlertService, AuthenticationService, UserService, ImageService, SessionService, TitleService } from '../../../shared/services';
import { Alert } from '../../../shared/models';
import { analyticsLayer } from '@shared/analyticsLayer';

@Component({
	selector: 'app-account-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

	@ViewChild('imageUpload') fileReference: ElementRef;
	@Input('user') user: any;
	@Input('orgList') orgList: Array<any>;
	@Output() navToTeams: EventEmitter<boolean> = new EventEmitter<boolean>();

	public isImageLoading: boolean = false;
	public resetPasswordForm: FormGroup;
	public userForm: FormGroup;
	public editPassword: boolean = false;
	public editPhone: boolean = false;
	public editPasswordError: string = null;
	public showPassword: boolean = false;

	constructor(
		private _alertService: AlertService,
		private _authenticationService: AuthenticationService,
		private _userService: UserService,
		private _imageService: ImageService,
		public _router: Router,
		private _ngZone: NgZone,
		private _route: ActivatedRoute,
		private _formBuilder: FormBuilder,
		private _titleService: TitleService
	) {
		this.editPassword = false; this.editPhone = false;
	}	// End-of constructor

	ngOnInit() {

		this._titleService.setTitle('My profile');
		analyticsLayer.trackPage();		
		this.createForms();

	}	// End-of ngOnInit

	logout(): void {

		this._authenticationService.logout().then(() => {
			this._ngZone.run(() => {
				this._router.navigate(['login']);
			});
		});

	}	// End-of logout

	private createForms(): void {

		this.resetPasswordForm = this._formBuilder.group({
			password: ['', [Validators.minLength(8), Validators.required]],
			confirm_password: ['', [this.matchValidator('password'), Validators.required]]
		});

		this.userForm = this._formBuilder.group({
			phone: [this.user.phone ? this.user.phone : '', [Validators.minLength(10), Validators.required]],
		});

	}	// End-of createResetPasswordForm

	onResetPasswordSubmit(): void {

		const AuthService = this._authenticationService,
			password = this.resetPasswordForm.value.confirm_password;

		this._userService
			.resetPassword(password)
			.then(data => {
					this._alertService.success(new Alert('Password successfully updated.'))
					// Clear form fields
					this.resetPasswordForm.reset();
					this.editPassword = false;
					// Navigate to login page
					this._router.navigate(['signin']);
				}, error => {
					this.editPasswordError = 'Profile picture failed to update. Must be PNG or JPEG File';
					console.error(error);
				});

	}	// End-of onResetPasswordSubmit

	private matchValidator(formControlName: string): ValidatorFn {

		return (thisControl: AbstractControl): { [key: string]: any; } => {
			if (thisControl.parent == undefined) {
				return null;
			} else {
				const thatControl = thisControl.parent.get(formControlName),
					isMatched = thisControl.value === thatControl.value ? true : false;

				return isMatched ? null : { 'mismatch': true };
			}
		};

	}	// End-of matchValidator

	updateUser(): void {
		this.user.phone = this.userForm.value.phone;
		this._userService.update(this.user).then(rtn => {
			this.editPhone = false;
			this._alertService.success(new Alert('Phone number successfully updated'));
		});
	}

	goToTeams(): void {
		this.navToTeams.emit(true);
	}

}	// End-of class TestComponent
