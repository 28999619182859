
/* Imports */
import { Component, Input, OnInit, OnDestroy, NgZone, ViewChild, ElementRef, AfterContentInit, Inject } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized, RouterModule, ActivatedRoute } from '@angular/router';
import { AuthenticationService, OrganizationService, UtilsService } from '../../shared/services';
import { byId } from '@shared/services/utils.service';
import { flagLayer, availableFeatureFlags } from "@shared/featureFlags";

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Organization } from "@shared/models";

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html',
	styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

	@ViewChild('content') content: ElementRef;
	@Input() isUnsubscribe: boolean;

	public currentProfilePage: { name, value, subText } = { name: "", value: "", subText: "" };
	public orgList: Array<any>;
	public user: any;
	public isLoading: boolean = true;

	public showSidebar: boolean = true;
	public freeToPaid: boolean = false;
	public isMobile: boolean = false;

	public profileItems: any = {
		profile: { name: "My Profile", value: "profile" },
		plan: { name: "Plans & Billing", value: "plan" },
		teams: { name: "Teams & Members", value: "teams", subText: "Members in a team will have access to all projects under that team." },
		receipt: { name: "Processing Receipts", value: "receipt" },
		alert: { name: "Alerts & Notifications", value: "alert" },
	};

	// properties to input into the PlanComponent
	selectedOrg: any;
	addNewCard: boolean;

	constructor(
		private _authService: AuthenticationService,
		private _orgService: OrganizationService,
		private _utilsService: UtilsService,
		private _router: Router,
		public _matDialogRef: MatDialogRef<AccountComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {

		// check if data is pre-populated to auto-open specific PaymentManagement pages
		if (data.selectedOrg) {
			this.currentProfilePage = this.profileItems[data.page];
			this.selectedOrg = data.selectedOrg;
			this.addNewCard = data.addNewCard;
		} else {
			this.currentProfilePage = this.profileItems[data];
		}

		this.user = this._authService.user;



		// TODO: @remove remove-subscription-required : refactor the initial profileItems
		if (flagLayer.isEnabled(availableFeatureFlags.removeSubscriptionRequirement))
		{
			// eslint-disable-next-line no-restricted-syntax
			if(flagLayer.isEnabled(availableFeatureFlags.newSubscription)) {
				this.profileItems.plan.name = "Subscriptions";
			} else {
				this.profileItems.plan.name = "Payment management";
			}
		// ^^^^ TODO: @remove-end remove subscription-required^^^^

			// DO NOT REMOVE WHEN refactoring remove-subscription
			if(flagLayer.isEnabled(availableFeatureFlags.disablePayments))
			{
				delete this.profileItems.plan;
			}
			//^^^ DO NOT REMOVE when refactoring subscription ^^^
		}


		this.getOrgList();
		this.isMobile = this._utilsService.getIsMobile();
		this.showSidebar = !this.isMobile;
		this.freeToPaid = false;
	}

	getOrgList(): void {
		this._orgService.getList().then(rtnList => {
			const orgList = rtnList.filter(x => x.active)
			this.checkOrgsUsers(orgList);
			if (!orgList?.length) {
				this.currentProfilePage = this.profileItems.teams;
			}
			this.orgList = orgList;
			this.isLoading = false;

		}, error => {
			console.error(error);
			this.isLoading = false;
		});
	}

	checkOrgsUsers = (orgList) => {
		orgList.forEach(org => {
			if (!org.users) org.users = [];
		})
	}

	getOrgListByRole(orgList, roleRequirements: Array<string> = ['admin']): Array<any> {
		return orgList.filter( org => {
			const role = org.users.find(byId(this.user.id))?.role;
			return roleRequirements.some((r) => r === role)
				?? roleRequirements.find((x) => x === "admin");
		});
	}

	selectOrg(orgId): void {
		this.selectedOrg = orgId;
	}

	ngOnInit() {
		if (this.isUnsubscribe) {
			this._router.navigate(['projects']);
		}
	}	// End-of ngOnInit

	toggleFullpage(e: boolean): void {
		this.showSidebar = !this.showSidebar;
	}

	closeModal(e?) {
		this._matDialogRef.close(e);
	}

	sendFeedback(): void {
		UtilsService.sendSupportEmail();
	}

	getObjectValues = (object) => Object.values(object)

}	// End-of class TestComponent
